document.addEventListener('DOMContentLoaded', function() {
    class AVTPopup {
        constructor(settings) {
            this.imageClass = settings.imageClass === null ? "" : settings.imageClass;
            this.descriptionClass = settings.descriptionClass === null ? "" : settings.descriptionClass;
            this.elements = {
                popupWrap: null,
                popupImg: null,
                popupImgWrap: null,
                popupDescr: null,
                popupClose: null,
                popupNext: null,
                popupPrev: null,
                galleryElements: [],
                images: [],
                counter: null,
                counterTotal: null,
                counterCurrent: null,
                galleryWrap: null
            }
            this.states = {
                activeIndex: 0,
                startPoint: null
            }
            this.description = settings.counter === null ? false : settings.description;
            this.counter = settings.counter === null ? false : settings.counter;
            this.gallery = settings.gallery === null ? false : settings.gallery;
            this.nextPrev = settings.nextPrev === null ? false : settings.nextPrev;
            this.zoom = settings.zoom === null ? true : settings.zoom;
            this.init();
        }

        init() {
            if (this.imageClass == "") {
                console.error("AVT Popup: No images class");
                return;
            }
            this.createPopup();
            this.setEvents();
        }
        setEvents() {
            this.showPopup();
            this.closePopup();
            this.zoomPicture();
            this.createGallery();
            this.createDescription();
            this.setGallery();
            this.keyboardEvents();
            this.setNextPrev();
            this.createCounter();
            this.touchEvents();
        }

        createPopup() {
            const body = document.querySelector("body");
            const popupWrap = document.createElement("div");
            const popupImg = document.createElement("img");
            const popupImgWrap = document.createElement("div");
            const popupClose = document.createElement("div");
            popupImgWrap.classList.add("avt-popup__image-wrap");
            popupClose.classList.add("avt-popup__close");
            popupImg.classList.add("avt-popup__image");
            popupWrap.classList.add("avt-popup");
            popupImgWrap.appendChild(popupImg);
            popupWrap.appendChild(popupImgWrap);
            popupWrap.appendChild(popupClose);
            body.appendChild(popupWrap);
            this.elements.popupClose = popupClose;
            this.elements.popupWrap = popupWrap;
            this.elements.popupImg = popupImg;
            this.elements.popupImgWrap = popupImgWrap;
        }
        createDescription() {
            const popupDescr = document.createElement("div");
            popupDescr.classList.add("avt-popup__description");
            this.elements.popupImgWrap.appendChild(popupDescr);
            this.elements.popupDescr = popupDescr;
        }
        showPopup() {
            const self = this;
            const images = document.querySelectorAll(this.imageClass);
            for (let i = 0; i < images.length; i++) {
                images[i].dataset.index = i;
                self.elements.images.push(images[i]);
                images[i].addEventListener("click", function(event){
                    event.preventDefault();
                    self.states.activeIndex = this.dataset.index;
                    let imgSrc = this.href;
                    let imgAlt = this.querySelector("img").alt;
                    if(imgSrc.indexOf("http://") == -1 || imgSrc.indexOf("https://")) {
                    }
                    self.elements.popupImg.src = imgSrc;
                    self.elements.popupImgWrap.style.backgroundImage = "url('" + imgSrc + "')";
                    if (self.description) {
                        let descrTxt = "";
                        if (self.descriptionClass != "") {
                            let descr = this.querySelector(self.descriptionClass);
                            if (descr === null) {
                                descr = this.parentElement.querySelector(self.descriptionClass);
                                if (!(descr)) {
                                    descr = this.parentElement.parentElement.querySelector(self.descriptionClass);
                                }
                            }
                            if (descr != null) {
                                descrTxt = descr.innerHTML;
                            }
                        }
                        if (descrTxt == "") {
                            self.elements.popupDescr.innerHTML = imgAlt;
                        }
                        else {
                            self.elements.popupDescr.innerHTML = descrTxt;
                        }
                    }
                    else {
                        self.elements.popupDescr.innerHTML = imgAlt;
                    }


                    self.elements.popupWrap.classList.add("open");
                    self.setActiveGalleryEl();
                    self.setCounter();
                });
            }
        }
        closePopup() {
            const self = this;
            this.elements.popupClose.addEventListener("click", function(){
                self.elements.popupWrap.classList.remove("open");
                self.elements.popupWrap.classList.remove("zoom");
                self.elements.popupImgWrap.classList.remove("zoom");
            });
            this.elements.popupWrap.addEventListener("click", function(){
                this.classList.remove("open");
            });
        }

        zoomPicture() {
            const self = this;
            if (this.zoom) {
                this.elements.popupImg.addEventListener("click", function(event){
                    event.preventDefault();
                    event.stopPropagation();
                    self.elements.popupWrap.classList.toggle("zoom");
                    this.parentElement.classList.toggle("zoom");
                });
            }
        }

        nextImage() {
            const activeIndex = this.states.activeIndex;
            let newIndex = parseInt(this.states.activeIndex) + 1;
            if (newIndex == this.elements.images.length) {
                newIndex = 0;
            }
            const nextEl = this.elements.galleryElements[newIndex];
            this.states.activeIndex = nextEl.dataset.index;
            this.elements.popupImg.src = nextEl.dataset.src;
            this.elements.popupImgWrap.style.backgroundImage = "url('" + nextEl.dataset.src + "')";
            this.elements.popupDescr.innerHTML = nextEl.dataset.description;
            this.setActiveGalleryEl();
            this.setCounter();
        }
        prevImage() {
            const activeIndex = this.states.activeIndex;
            let newIndex = parseInt(this.states.activeIndex) - 1;
            if (newIndex < 0) {
                newIndex = this.elements.images.length - 1;
            }
            const nextEl = this.elements.galleryElements[newIndex];
            this.states.activeIndex = nextEl.dataset.index;
            this.elements.popupImg.src = nextEl.dataset.src;
            this.elements.popupImgWrap.style.backgroundImage = "url('" + nextEl.dataset.src + "')";
            this.elements.popupDescr.innerHTML = nextEl.dataset.description;
            this.setActiveGalleryEl();
            this.setCounter();
        }
        setNextPrev() {
            const self = this;
            if (this.nextPrev) {
                const popupNext = document.createElement("div");
                const popupPrev = document.createElement("div");
                popupNext.classList.add("avt-popup__next");
                popupPrev.classList.add("avt-popup__prev");
                this.elements.popupImgWrap.appendChild(popupNext);
                this.elements.popupImgWrap.appendChild(popupPrev);
                this.elements.popupNext = popupNext;
                this.elements.popupPrev = popupPrev;

                popupNext.addEventListener("click", function(event){
                    event.preventDefault();
                    event.stopPropagation();
                    self.nextImage();
                });
                popupPrev.addEventListener("click", function(event){
                    event.preventDefault();
                    event.stopPropagation();
                    self.prevImage();
                });
            }
        }

        keyboardEvents() {
            const self = this;
            window.addEventListener("keydown", function(e){
                let imageClass = self.imageClass;
                if (imageClass.indexOf(".") != -1) {
                    imageClass = imageClass.replace(".", "");
                }

                // Escape
                if((e.key == 'Escape' || e.key == 'Esc' || e.keyCode==27) && (self.elements.popupWrap.className.indexOf("open") != -1)){
                    e.preventDefault();
                    self.elements.popupWrap.classList.remove("open");
                    self.elements.popupWrap.classList.remove("zoom");
                    self.elements.popupImgWrap.classList.remove("zoom");
                }
                // Left arrow
                if((e.key == 'ArrowLeft' || e.keyCode == 37) && (self.elements.popupWrap.className.indexOf("open") != -1)){
                    self.prevImage();
                }
                // Right arrow
                if((e.key == 'ArrowRight' || e.keyCode == 39) && (self.elements.popupWrap.className.indexOf("open") != -1)){
                    self.nextImage();
                }
            });
        }

        createGallery() {
            const self = this;
            if (this.gallery) {
                const images = document.querySelectorAll(this.imageClass);
                const galleryWrap = document.createElement("div");
                galleryWrap.classList.add("avt-popup__gallery");
                this.elements.popupWrap.appendChild(galleryWrap);
                this.elements.gallery = galleryWrap;
                for (var i = 0; i < images.length; i++) {
                    const bgSrc = images[i].href;
                    const img = images[i].querySelector("img");
                    let imgAlt;
                    if (img != null) {
                        imgAlt = images[i].querySelector("img").alt;
                    }
                    else {
                        imgAlt = "";
                    }
                    const galleryEl = document.createElement("div");
                    galleryEl.classList.add("avt-popup__gallery-item");

                    if (this.description && this.descriptionClass) {

                        let descrTxt = "";
                        let descr = images[i].querySelector(self.descriptionClass);
                        if (descr === null) {
                            descr = images[i].parentElement.querySelector(self.descriptionClass);
                            if (descr === null) {
                                const parentEl = images[i].parentElement;
                                descr = parentEl.parentElement.querySelector(self.descriptionClass);
                            }
                        }
                        if (self.descriptionClass != null && descr != null) {
                            descrTxt = descr.innerHTML;
                        }
                        galleryEl.dataset.description = descrTxt;
                    }
                    else {
                        galleryEl.dataset.description = imgAlt;
                    }

                    galleryEl.dataset.index = i;
                    galleryEl.dataset.src = bgSrc;
                    galleryEl.style.backgroundImage = "url('" + bgSrc + "')";
                    this.elements.galleryElements.push(galleryEl);
                    this.elements.gallery.appendChild(galleryEl);
                }
            }
        }
        setGallery() {
            const self = this;
            if (this.gallery) {
                for (var i = 0; i < this.elements.galleryElements.length; i++) {
                    const galleryEl = this.elements.galleryElements[i]
                    galleryEl.addEventListener("click", function(event){
                        event.preventDefault();
                        event.stopPropagation();
                        self.states.activeIndex = this.dataset.index;
                        self.elements.popupImg.src = this.dataset.src;
                        self.elements.popupImgWrap.style.backgroundImage = "url('" + this.dataset.src + "')";
                        self.elements.popupDescr.innerHTML = this.dataset.description;
                        self.elements.popupWrap.classList.add("open");
                        self.setActiveGalleryEl();
                        self.setCounter();
                    });
                }
            }
            else {
                self.elements.popupWrap.classList.add("--img");
            }
        }
        setActiveGalleryEl() {
            const self = this;
            if (this.gallery) {
                var galleryElements = this.elements.galleryElements;
                for (var i = 0; i < galleryElements.length; i++) {
                    if (i == this.states.activeIndex) {
                        galleryElements[i].classList.add("active");
                    }
                    else {
                        galleryElements[i].classList.remove("active");
                    }
                }
            }
        }

        createCounter() {
            if (this.counter) {
                const counter = document.createElement("div");
                const counterCurrent = document.createElement("div");
                const counterTotal = document.createElement("div");
                counter.classList.add("avt-popup__counter");
                counterCurrent.classList.add("avt-popup__counter-current");
                counterTotal.classList.add("avt-popup__counter-total");
                counter.appendChild(counterCurrent);
                counter.appendChild(counterTotal);
                this.elements.popupImgWrap.appendChild(counter);
                this.elements.counter = counter;
                this.elements.counterTotal = counterTotal;
                this.elements.counterCurrent = counterCurrent;
            }
        }
        setCounter() {
            const total = this.elements.images.length;
            const current = parseInt(this.states.activeIndex) + 1;
            this.elements.counterTotal.innerText = total;
            this.elements.counterCurrent.innerText = current;
        }
        touchEvents() {
            const self = this;
            this.elements.popupImgWrap.addEventListener("touchstart", function(event){
                self.states.startPoint = event.touches[0].clientX;
            });
            this.elements.popupImgWrap.addEventListener("touchmove", function(event){
                const newPoint = event.touches[0].clientX;
                if (newPoint < self.states.startPoint) {
                    if (self.states.startPoint - newPoint > window.innerWidth / 10) {
                        self.nextImage();
                        self.states.startPoint = newPoint;
                    }
                }
                else if (newPoint > self.states.startPoint) {
                    if (newPoint - self.states.startPoint > window.innerWidth / 10) {
                        self.prevImage();
                        self.states.startPoint = newPoint;
                    }
                }
            });
        }
    }

    let descriptionClass = null;
    let description = false;

    const popupSettings = {
        imageClass: ".imagesInContent",
        description: true,
        descriptionClass: descriptionClass,
        gallery: true,
        nextPrev: true,
        counter: true
    }
    if (document.querySelectorAll(".imagesInContent").length > 0) {
        let links = document.querySelectorAll(".imagesInContent");
        for (var i = 0; i < links.length; i++) {
            links[i].addEventListener("click", function(event){
                event.preventDefault();
            });
        }
        window.addEventListener("load", function(){
            let timer = setTimeout(function(){
                const articlePopup = new AVTPopup(popupSettings);
            }, 10)
        });
    }
})
