import AVTPopup from './components/avt-popup';
import Simplebar from "simplebar";
import sliders from './components/sliders';
import xhrwrap from "./components/xhrWrap";
import farnellFinder from "./components/farnellFinder";
import subscriptionLogin from "./components/login";
import questionnairePage from "./components/questionnaire";
import scrollArticles from './components/scroll-article';
import newsletter from './components/newsletter';
import docCookies from "./components/docCookies";

const XHRWrap = xhrwrap();

document.addEventListener('DOMContentLoaded',function() {
    farnellFinder(XHRWrap);
    sliders();
    subscriptionLogin();
    questionnairePage();
    newsletter();
    scrollArticles(Simplebar);

    const stickyNewsletter = document.querySelector('.sticky-newsletter');
    const stickyNewsletterBtn = document.getElementsByClassName('sticky-newsletter__close')[0];
    if(stickyNewsletterBtn) {
        stickyNewsletterBtn.addEventListener('click',function() {
            const cookie = docCookies.showCookie("stickyNewsletter");
            docCookies.setCookie("stickyNewsletter", typeof cookie === "undefined" ? 1 : parseInt(cookie) + 1, 1, "/");
            stickyNewsletter.classList.add('hidden');
        });
    };


    const connectedBtn = document.getElementsByClassName('single-article__connected-btn-wrapper')[0];
    const connectedHidden = document.querySelector('.single-article__connected .hidden');

    if(connectedBtn) {
        connectedBtn.addEventListener('click',function() {
            if(connectedHidden.classList.contains('show')) {
                connectedHidden.classList.remove('show');
            } else {
                connectedHidden.classList.add('show');
            }
        })
    }

    // Burger menu

    const burgerIcon = document.getElementsByClassName('burger-js');
    const burgerWrapper = document.getElementsByClassName('burger-menu')[0];
    const closeBurger = document.getElementsByClassName('burger-menu__wrapper-close')[0];
    const burgerRight = document.getElementsByClassName('burger-menu__wrapper')[0];

    if(burgerIcon) {
        for(var i = 0; i < burgerIcon.length; i++) {
            burgerIcon[i].addEventListener('click',function(){
                if(burgerWrapper.classList.contains('show')) {
                    burgerWrapper.classList.remove('show');
                } else {
                    burgerWrapper.classList.add('show');
                }
            })
        }
    }

    if(closeBurger) {
        closeBurger.addEventListener('click',function() {
            if(burgerWrapper.classList.contains('show')) {
                burgerWrapper.classList.remove('show');
            } else {
                burgerWrapper.classList.add('show');
            }
        })
    }

    if(burgerWrapper) {
        burgerWrapper.addEventListener('click', function(target) {
            if(target.target.classList.contains('burger-menu')) {
                if(burgerWrapper.classList.contains('show')) {
                    burgerWrapper.classList.remove('show');
                } else {
                    burgerWrapper.classList.add('show');
                }
            }
        })
    }

    // Toc

    const toc = document.getElementsByClassName('toc')[0];

    if(toc) {
        const tocTitle = toc.getElementsByClassName('toc__top-title')[0];
        const tocBurger = toc.getElementsByClassName('toc__top-title-lines')[0];
        const tocList   = toc.getElementsByClassName('toc__list')[0];
        let tocListHeight = tocList.getBoundingClientRect().height;
        const tocBtn = document.querySelectorAll(".toc__list-btn");
        console.log(tocBtn);
        // if(tocList) {
        //     tocList.style.maxHeight = tocListHeight + 'px';
        // }
        if(tocTitle) {
            tocTitle.addEventListener('click',function(){
                if(tocBurger) {
                    if(tocBurger.classList.contains('closed')) {
                        tocBurger.classList.remove('closed');
                    } else {
                        tocBurger.classList.add('closed');
                    }
                }
                if(tocList) {
                    if(tocList.classList.contains('hidden')) {
                        tocList.classList.remove('hidden');
                    } else {
                        tocList.classList.add('hidden');
                    }
                }
            })
        }
        if (tocBtn.length > 0) {
            for (var i = 0; i < tocBtn.length; i++) {
                tocBtn[i].addEventListener("click", function(e){
                    this.parentElement.classList.toggle("open");
                    if (!this.parentElement.classList.contains("open")) {
                        window.scrollTo(0, this.parentElement.offsetTop);
                    }
                });
            }
        }
    }

    // socials right bottom

    const socialsWrapper = document.getElementsByClassName('socials-bottom-js')[0];
    const socialsBtn = document.querySelector('.socials-bottom-js .socials-left__item.share');

    if(socialsBtn) {
        socialsBtn.addEventListener('click',function() {
            if(socialsWrapper.classList.contains('show')) {
                socialsWrapper.classList.remove('show');
            } else {
                socialsWrapper.classList.add('show');
            }
        })
    }

    // Poprawianie tagów code

    var codeTags = document.querySelectorAll('div[class^="language-"], div[class*=" language-"], pre[class^="language-"], pre[class*=" language-"]');

    if(codeTags) {
        for(var i = 0; i < codeTags.length; i++) {
            let nodeName;
            let text;
            if (codeTags[i].innerHTML.indexOf('\n') != -1) {
                text = codeTags[i].innerHTML.split('\n').join('</code>\n<code>');
            }
            else if (codeTags[i].innerHTML.indexOf('<br>') != -1) {
                text = "<code>" + codeTags[i].innerHTML.split('<br>').join('</code>\n<code>') + "</code>";
            }
            else if (codeTags[i].innerHTML.indexOf('</br>') != -1) {
                text = "<code>" + codeTags[i].innerHTML.split('</br>').join('</code>\n<code>') + "</code>";
            } else {
                text = codeTags[i].innerHTML ;
            }
            codeTags[i].innerHTML = '';
            codeTags[i].innerHTML = text;
        }
    }

    // Wyszukiwarka header

    var searchBtn = document.querySelectorAll('.header__right-icons-item.search');
    var searchWrapper = document.getElementsByClassName('header__right-search');
    var closeSearch = document.getElementsByClassName('header__right-search-close');

    if(searchBtn) {
        for(var i = 0; i < searchBtn.length; i++) {
            searchBtn[i].addEventListener('click',function() {
                if(searchWrapper) {
                    for(var i = 0; i < searchWrapper.length; i++) {
                        if(searchWrapper[i].classList.contains('show')) {
                            searchWrapper[i].classList.remove('show');
                        } else {
                            searchWrapper[i].classList.add('show');
                        }
                    }
                }
            })
        }
    }
    if(closeSearch) {
        for(var i = 0; i < closeSearch.length; i++) {
            closeSearch[i].addEventListener('click',function() {
                if(searchWrapper) {
                    for(var i = 0; i < searchWrapper.length; i++) {
                        if(searchWrapper[i].classList.contains('show')) {
                            searchWrapper[i].classList.remove('show');
                        } else {
                            searchWrapper[i].classList.add('show');
                        }
                    }
                }
            })
        }
    }

    // Dropdown selects subpages:

    const subpageSelect = document.getElementsByClassName('subpage-category-select')[0];

    if(subpageSelect) {
        const selectWrapper = subpageSelect.getElementsByClassName('category-select')[0];
        subpageSelect.addEventListener('click',function(){
            if(selectWrapper.classList.contains('active')) {
                selectWrapper.classList.remove('active');
            } else {
                selectWrapper.classList.add('active');
            }
        })
    }

    // Search subpage clean input

    const cleanBtn = document.getElementsByClassName('search-wrapper__form-icons-close')[0];
    const inputSearch = document.querySelector('.search-wrapper__form-input input');

    if(cleanBtn) {
        cleanBtn.addEventListener('click',function(){
            inputSearch.value = '';
        })
    }

    const popupSettings = {
        imageClass: ".imagesInContent",
        description: true,
        gallery: true,
        nextPrev: true,
        counter: true,
        zoom: false,
        excludedItems: {
            class: [],
            data: ["data-oryginal"],
            id: []
        }
    }

    // Sticky header

    var header = document.getElementsByClassName('header-js')[0];

    if(header) {
        window.addEventListener('scroll', () => {
            const currentScroll = window.pageYOffset;
            if(currentScroll > 178) {
                header.classList.add('header-show');
            } else {
                header.classList.remove('header-show');
            }
        })
    }

    // For you - subpage

    const forYouDescription = document.getElementsByClassName('for-you__page-description')[0];
    const forYouShowMoreBtn = document.querySelector('.for-you__page-description .show-more');
    const forYouHide        = document.querySelector('.for-you__page-description .hide-description')

    if(forYouDescription) {
        forYouShowMoreBtn.addEventListener('click',function() {
            forYouDescription.classList.add('show');
        })
    }
    if(forYouHide) {
        forYouHide.addEventListener('click',function() {
            forYouDescription.classList.remove('show');
        })
    }

    // Contact form


    const contactForm = document.querySelector('.contact__wrapper-form');
    let statusWrapperMain = document.querySelector('.contact__wrapper-status-info');
    let statusWrapper = document.querySelector('.contact__wrapper-status-info-text');
    let sendingContact = false;

    if (contactForm) {
        const contactSubmit = contactForm.querySelector("input[type='submit']");
        const body = document.querySelector("body");
        contactForm.addEventListener('submit',function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (!sendingContact) {
                sendingContact = true;
                body.classList.add("loading");
                statusWrapperMain.classList.remove('show');
                statusWrapperMain.classList.remove('error');
                fetch("/api/v1/contact/", {
                    method: "POST",
                    body: new FormData(this),
                })
                    .then(res => res.json())
                    .then(
                        result => {
                            console.log(result);
                            if(result.status === true) {
                                if(statusWrapper) {
                                    statusWrapperMain.classList.add('show');
                                    statusWrapper.innerHTML = result.message.send;
                                    body.classList.remove("loading");
                                    contactSubmit.disabled = true;
                                    sendingContact = false;
                                    window.scrollTo(0,0);
                                }
                            }
                            else {
                                if(statusWrapper) {
                                    statusWrapperMain.classList.add('show');
                                    statusWrapperMain.classList.add('error');
                                    const messageArr = [];
                                    for (let message in result.message) {
                                        if (result.message.hasOwnProperty(message)) {
                                            messageArr.push(result.message[message]);
                                        }
                                    }
                                    statusWrapper.innerHTML = messageArr.join(", ");
                                    sendingContact = false;
                                    body.classList.remove("loading");
                                    window.scrollTo(0,0);
                                }
                            }
                        }
                    )
                    .catch(err => console.log(err))
            }
        })
    }

})
