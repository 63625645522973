const questionnairePage = () => {
    const questionnaire = document.querySelector(".questionnaire-page");
    if (questionnaire) {
        const questionnaireInfo = questionnaire.querySelector(".questionnaire-page__info");
        if (questionnaireInfo) {
            questionnaireInfo.addEventListener("click", function(e){
                if (e.target.classList.contains("btn")) {
                    this.classList.toggle("open");
                }
            });
        }
        const questionnaireMessage = questionnaire.querySelectorAll(".questionnaire-page__message");
        if (questionnaireMessage.length > 0) {
            for (var i = 0; i < questionnaireMessage.length; i++) {
                questionnaireMessage[i].addEventListener("click", function(e){
                    if (e.target.dataset.hasOwnProperty("close")) {
                        this.classList.add("closed");
                    }
                });
            }
            const offsetMessage = questionnaireMessage[0].offsetTop;
            window.scrollTo(0, offsetMessage - 100);
        }
    }
}

export default questionnairePage;
