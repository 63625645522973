const newsletter = () => {
    const newsletterInfo = document.querySelector(".newsletter__middle-form-second-description");
    if (newsletterInfo) {
        newsletterInfo.addEventListener("click", function(e){
            if (e.target.classList.contains("btn")) {
                this.classList.toggle("open");
            }
        });
    }
}
export default newsletter;
