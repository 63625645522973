const login = () => {
    const loginForm = document.querySelector(".subscription-login__form");
    if (loginForm) {
        const loginFormError = document.querySelector(".subscription-login__form-error");
        loginForm.addEventListener("submit", function(e){
            e.preventDefault();
            fetch(this.action + e.target.code.value)
                .then(res => res.json())
                .then(res => {
                    if (res.error && loginFormError) {
                        console.log(res.error);
                        if (res.error === "AlreadyLogged") {
                            loginFormError.innerText = "Użytkownik jest już zalogowany.";
                        }
                        else if (res.error === "InvalidAccessCode") {
                            loginFormError.innerText = "Nieprawidłowy kod dostępu.";
                        }
                        else {
                            loginFormError.innerText = "Ooops! Coś poszło nie tak, spróbuj ponownie za chwilę.";
                        }
                    }
                    else {
                        document.location.href = location.origin;
                    }
                })
                .catch(error => {
                    if (loginFormError) {
                        loginFormError.innerText = "Ooops! Coś poszło nie tak, spróbuj ponownie za chwilę.";
                    }
                })
        });
    }
    const logout = document.querySelectorAll("[data-user='logout']");
    if (logout.length > 0) {
        for (var i = 0; i < logout.length; i++) {
            logout[i].addEventListener("click", function(e){
                fetch("/access-code/logout")
                    .then(res => res.json())
                    .then(res => {
                        document.location.href = location.origin;
                    })
                    .catch(err => console.log(err))
            });
        }
    }


    const userInfoWraps = document.querySelectorAll(".user-info__wrap");
    if (userInfoWraps.length > 0) {
        for (var i = 0; i < userInfoWraps.length; i++) {
            const btn = userInfoWraps[i].querySelector(".user-info__btn");
            btn.addEventListener("click", function(e){
                this.closest(".user-info__wrap").classList.toggle("open");
            });
        }
    }
}

export default login;
