const farnellFinder = (XHRWrap) => {
    const farnellForms = document.querySelectorAll(".farnell-search__form");
    let timer;
    for (var i = 0; i < farnellForms.length; i++) {
        farnellForms[i].addEventListener('submit',function (e) {
            e.preventDefault();
        })
        farnellForms[i].addEventListener("input", function(e){
            const self = this;
            const resultList = this.querySelector(".farnell-search__result");
            if (e.target.value.length > 2) {
                clearTimeout(timer);
                resultList.innerHTML = "";
                self.classList.remove("result");
                this.classList.add("search");
                timer = setTimeout(function(){
                    let findProduct = new XHRWrap({
                        url: "/api/v1/farnell/search-products?query=" + e.target.value,
                        type: "GET",
                    });
                    findProduct.send().then(
                        result => {
                            self.classList.remove("search");
                            self.classList.add("result");
                            if (result.hasOwnProperty("products") && result.products.length > 0) {
                                for (var i = 0; i < result.products.length; i++) {
                                    const product = result.products[i];
                                    const element = document.createElement("a");
                                    element.classList.add("farnell-search__result-item");
                                    if (product.hasOwnProperty("displayName")) {
                                        element.innerText = product.displayName;
                                    }
                                    if (product.sku) {
                                        // element.href = product.datasheets[0].url;
                                        element.href = 'https://pl.farnell.com/' + product.sku;
                                        element.target = "_blank";
                                    }
                                    resultList.appendChild(element);
                                }
                            }
                        },
                        error => {
                            self.classList.remove("search");
                            console.log(error);
                        }
                    )
                }, 1000)
            }

        });
    }
}

export default farnellFinder;
