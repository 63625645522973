import Swiper from 'swiper';

const sliders = () => {

    // Header slider

    const swiperHeaderTags = new Swiper('.swiper-header-tags', {
        slidesPerView: "auto",
        spaceBetween: 4
    });

    // Home slider
    const sliderTop = document.getElementsByClassName('.slider-home__top')[0];
    const sliderBottom = document.getElementsByClassName('.slider-home__bottom')[0];
    const sliderHome = document.querySelector('.slider-home');

    const sliderTopHome = new Swiper('.slider-home__top', {
        spaceBetween: 0,
        pagination: {
            el: '.swiper-pagination',
        },
        autoplay: {
            delay: 3000,
        },
        navigation: {
            nextEl: '.swiper-navigation__right',
            prevEl: '.swiper-navigation__left',
        },
    });

    const sliderBottomHome = new Swiper('.slider-home__bottom', {
        spaceBetween: 0,
    });

    if(sliderHome) {
        sliderTopHome.controller.control = sliderBottomHome;
        sliderBottomHome.controller.control = sliderTopHome;
    }

    // Uk slider

    let countUkSlides = document.querySelectorAll('.slider-uk-swiper .swiper-slide').length / 2;

    const sliderUK = new Swiper('.slider-uk-swiper', {
        spaceBetween: 0,
        slidesPerView: 'auto',
        centeredSlides: true,
        initialSlide: Math.ceil(countUkSlides) -1,
        navigation: {
            nextEl: '.slider-uk__nav-right',
            prevEl: '.slider-uk__nav-left',
        },
    });

    // AVT shop products slider:

    const prevButton = document.getElementsByClassName('shop-slider__navigation-prev')[0];
    const nextButton = document.getElementsByClassName('shop-slider__navigation-next')[0];
    const allSlides = document.querySelectorAll('.shop-slider__container .swiper-slide');

    const swiperProducts = new Swiper('.shop-slider__container', {
        slidesPerView: "auto",
        spaceBetween: 30,
        watchSlidesVisibility: true,
        slideVisibleClass: 'shop-slider__visible',
        navigation: {
            nextEl: '.shop-slider__navigation-next-wrapper',
            prevEl: '.shop-slider__navigation-prev-wrapper',
        },
        on: {
            init: function () {
                if(allSlides[allSlides.length-1].classList.contains('shop-slider__visible')) {
                    nextButton.classList.add('hidden');
                } else {
                    nextButton.classList.remove('hidden');
                }
                if(allSlides[0].classList.contains('shop-slider__visible')) {
                    prevButton.classList.add('hidden');
                } else {
                    prevButton.classList.remove('hidden');
                }
            },
            activeIndexChange: function () {
                if(allSlides[allSlides.length-1].classList.contains('shop-slider__visible')) {
                    nextButton.classList.add('hidden');
                } else {
                    nextButton.classList.remove('hidden');
                }
                if(allSlides[0].classList.contains('shop-slider__visible')) {
                    prevButton.classList.add('hidden');
                } else {
                    prevButton.classList.remove('hidden');
                }
            }
        }
    });
}

export default sliders;